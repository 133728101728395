import React, { useReducer, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import invert from 'lodash/invert';

import { cloneDeep, isEmpty, isEqual, xorWith, join, split, find, filter, map, get, groupBy } from 'lodash';
import { Popover, Select, Table as AntTable } from 'antd';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import SelectStone from 'components/common/DiamondListing/SelectStone';
import Heading from '../common/Heading';
import { SEARCH_PAGES } from '../../containers/APIFunctions';
import ListingTop from '../common/DiamondListing/ListingTop';
import DiamondGridView from './DiamondGrid';
import Table, { LIMIT } from './TableBack';
import FilterTop from '../common/DiamondListing/FilterTop';
import {
  getColumn,
  fetchDiamondList,
  fetchDiamondPaginate,
  getPath,
  LISTINGPAGES,
  fetchCart,
  fetchWatch,
  TITLE,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  fetchNotes,
  fetchOfficeList,
  isMobile,
  fetchEnquiries,
  fetchBlockDiamonds,
  getProjectSetting,
  fetchFormalMeetList,
} from './DiamondListFunctions';
import {
  trackTypeObj,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  SEARCH_TAGS,
  MULTIPLE_SEARCH,
  PROJECT_SETTINGS_TYPE,
  BASE_URL,
} from '../../constants/Common';
import NoDataShow from '../common/NoDataShow';
import MobileViewList from './MobileViewList';
import MasterTags from './MasterTags';
import UtilService from '../../services/util';
import { GET_EXCLUSIVE_COLLECTION } from '../../constants/apiConstant';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import last from 'lodash/last';
import { AllStatusShow } from 'components/common/DiamondListing/AllStatusShow';
import { useBoolean } from 'util/hooks';
import { ReactComponent as USAFlag } from 'assets/svg/country/flag-usa.svg';
import BoothAppointment from 'components/common/DiamondListing/BoothAppointment';

import { FORMAL_MEET_COLUMN } from './FormalMeetColumn';
import { SHOW_DATA } from 'constants/ActionTypes';
import { NOTE_UPSERT } from '../../constants/apiConstant';
import OpenNotification from '../common/CommonButton/OpenNotification';

function getElementByClass(name) {
  if (document.getElementsByClassName(name) && document.getElementsByClassName(name)[0])
    return document.getElementsByClassName(name)[0];
  else return null;
}

export function getGridHeight(allClasses = [], initial = 0) {
  const allContainers = {};
  allClasses.forEach((classname) => {
    allContainers[classname] = document.querySelector(classname?.[0] === '.' ? classname : `.${classname}`);
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).forEach((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  const module = last(split(window.location.pathname, '/'));
  const data = module.replace('-', ' ');
  if (loading) return <></>;
  //<NoDataShow message={<Spin />} />;
  else if (length === 0)
    return (
      <NoDataShow
        message={
          getPath() === LISTINGPAGES.WATCHLIST ||
            getPath() === LISTINGPAGES.REMINDER ||
            getPath() === LISTINGPAGES.QUOTE
            ? `Your ${data} is empty.`
            : getPath() === LISTINGPAGES.NOTE
              ? 'Your note list is empty.'
              : getPath() === LISTINGPAGES.OFFICE
                ? 'No view request found.'
                : getPath() === LISTINGPAGES.FORMAL_MEET
                  ? 'No formal meet found.'
                  : getPath() === LISTINGPAGES.CONFIRM
                    ? 'No confirm diamonds found.'
                    : getPath() === LISTINGPAGES.HOLD
                      ? 'No hold diamonds found.'
                      : getPath() === LISTINGPAGES.MATCHPAIR
                        ? 'No couple stone found for requested search criteria.'
                        : getPath() === LISTINGPAGES.ENQUIRY
                          ? 'Your enquiry list is empty.'
                          : getPath() === LISTINGPAGES.CART
                            ? 'Your cart list is empty.'
                            : getPath() === LISTINGPAGES.MATCHPAIR
                              ? 'No couple stone found for requested search criteria.'
                              : getPath() === LISTINGPAGES.SPECIAL
                                ? 'No diamonds added in exclusive stone.'
                                : getPath() === LISTINGPAGES.STONE_OF_DAY
                                  ? 'No diamonds added in feature stone.'
                                  : getPath() === LISTINGPAGES.UPCOMING
                                    ? 'No diamonds added in new arrival.'
                                    : getPath() === LISTINGPAGES.SHOW
                                      ? 'No diamonds found.'
                                      : ''
        }
      />
    );
  else return <></>;
}

export function getState() {
  const stateVar = {
    listview: true,
    data: [],
    page: 1,
    limit: LIMIT,
    sum: {},
    columns: [],
    count: 0,
    loading: false,
    checked: [],
    inTrackDiamonds: [],
    defaultChecked: [],
    inBlockDiamonds: [],
  };
  return stateVar;
}

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const DiamondList = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));

  const sortRef = useRef([]);
  const [listView, setListView] = useState(true);
  const [ids, setIds] = useState([]);
  const [exclusiveTitle, setExclusiveTitle] = useState('');
  const [promotionDis, setPromotionDis] = useState({});
  const [showDis, setShowDis] = useState({});
  const [offerDis, setOfferDis] = useState({});
  const [visible, setVisible] = useBoolean();

  const { showData } = useSelector((store) => store?.auth);
  const checked = useSelector((state) => state.diamondData.selectedRows[location.pathname]) || [];
  let isaccount = useMemo(() => location.pathname.split('/')[1] === 'account', [location]);

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
  const upcomingData = checked.filter((data) => data.wSts === 'U');
  const { data: bidConfig } = useSelector((store) => store?.bidConfig);

  useEffect(() => {
    if (isaccount && !isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    getProjectSetting(PROJECT_SETTINGS_TYPE.PROMOTION_DISCOUNT, (cb) => {
      setPromotionDis(cb);
    });
    getProjectSetting(PROJECT_SETTINGS_TYPE.OFFER_DISCOUNT, (cb) => {
      setOfferDis(cb);
    });

    getProjectSetting(PROJECT_SETTINGS_TYPE.SHOW_DISCOUNT, (cb) => {
      setShowDis(cb);
    });
  }, []);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        //clear selected rows on path changes
        dispatch({ type: 'RESET_SELECTED_ROWS' });
        isaccount = route.pathname.split('/')[1] === 'account';
        setState(cloneDeep(getState()));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState()));
      }
    });
    return function cleanup() {
      listener();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    const searchParam = window.location.search.substring(1)?.split('&')[0];

    window.history.pushState(null, '', `?${searchParam}`);

    setIds(
      split(searchParam, ',').map((id, index) => {
        const obj = { id };
        if (index === 0) {
          obj['isActive'] = true;
        }
        return obj;
      }),
    );

    if (!searchParam && getPath() === LISTINGPAGES.LIST) {
      history.push('/' + LISTINGPAGES.DASHBOARD);
    }

    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    fetch();
  }, [state.page, state.limit, ids]);

  useEffect(() => {
    getExclusiveTitle();
  }, [ids, currentType.current]);

  const getExclusiveTitle = () => {
    const obj = {
      ...GET_EXCLUSIVE_COLLECTION,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        return 'Exclusive Collection';
      } else if (data && data.code === 'OK') {
        if (data.data) {
          const list = data.data.list;
          setExclusiveTitle(list);
        }
      }
    });
  };

  const handleSaveNotes = useCallback((id, notes, defaultValue) => {
    if (notes !== defaultValue) {
      const obj = NOTE_UPSERT;
      obj.request = {
        diamonds: [{ diamond: id, remarks: notes?.trim() }],
      };
      UtilService.callApi(obj, function (err, data) {
        if (err) {
          OpenNotification({
            type: 'error',
            title: data ? data.message : err && err.data ? err.data.message : '',
          });
          fetch();
        } else if (data && data.code === 'OK') {
          OpenNotification({
            type: 'success',
            title: data?.message || 'Your Request is updated successfully.',
          });
          fetch();
        }
      });
    }
  }, []);

  const setResponse = (res) => {
    const columns = getPath() === LISTINGPAGES.FORMAL_MEET ? FORMAL_MEET_COLUMN : getColumn(state.page);
    const isNotePage = getPath() === LISTINGPAGES.NOTE;
    const noteKey = isNotePage ? 'remarks' : 'usernotes';
    const noteColumnPage = [
      LISTINGPAGES.CART,
      LISTINGPAGES.WATCHLIST,
      LISTINGPAGES.REMINDER,
      LISTINGPAGES.OFFICE,
      LISTINGPAGES.QUOTE,
      LISTINGPAGES.HOLD,
      LISTINGPAGES.ENQUIRY,
      LISTINGPAGES.NOTE,
    ];
    if (noteColumnPage?.includes(getPath())) {
      const newColumn = {
        Header: 'Notes',
        accessor: noteKey,
        id: noteKey,
        minWidth: '100px',
        Cell: ({ row, data }) => (
          <span className="tableInput noteAdd">
            <input
              defaultValue={row?.original[noteKey]}
              name={`input${row?.original?.id}`}
              onBlur={(e) => handleSaveNotes(row?.original?.id, e?.target?.value, row?.original[noteKey])}
            />
          </span>
        ),
      };

      if (isNotePage) {
        const noteIndex = columns.findIndex((data) => data.Header === 'Notes');
        if (noteIndex) {
          columns[noteIndex] = newColumn;
        }
      } else {
        // Insert the new column at index 13
        columns.splice(11, 0, newColumn);
      }
    }

    if (res) {
      // CHECK THIS
      let diamondData = res.data || [],
        defaultChecked = [];
      console.log(getPath(), 'path')
      let grp
      if ('diamond-list' === getPath()) {
        grp = groupBy(diamondData, 'shpNm');
        if (Object.keys(grp).length != 1) {
          Object.keys(grp).forEach((key) => {
            grp[key][0].isOfferHeader = true;
            diamondData = [...diamondData, ...grp[key]];
          });
        }
      }



      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (
              defaultChecked.includes(
                JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)) === '/account/confirm-list'
                  ? d.diamondId
                  : d.id,
              )
            ) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      let uniqueArr = diamondData;

      if ('diamond-list' === getPath()) {
        let groupedItems = diamondData.reduce((group, item) => {
          let { shpNm } = item;
          group[shpNm] = group[shpNm] ?? [];
          group[shpNm].push(item);
          return group;
        }, {});
        let newDiamond = []
        Object.keys(groupedItems).forEach((key) => {
          groupedItems[key].forEach((item) => {
            newDiamond.push(item)
          })
        })
        if (Object.keys(groupedItems).length != 1) {
          uniqueArr = [...new Set(newDiamond)];
        }
      }

      setState({
        ...state,
        ...res,
        data: uniqueArr,
        defaultChecked,
        columns,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const handleSort = (currentSort) => {
    if (
      currentSort.length !== sortRef.current.length ||
      JSON.stringify(currentSort) !== JSON.stringify(sortRef.current)
    ) {
      sortRef.current = currentSort;
      fetch();
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const currentId = ids.find((id) => !!id.isActive);

    const payload = { ...state, sort: sortRef.current };

    if (getPath() === LISTINGPAGES.LIST) {
      if (currentId && currentId.id) {
        fetchDiamondList(payload, [{ diamondSearchId: currentId.id }], setResponse, true);
      }
    }
    // else if (getPath() === LISTINGPAGES.UPCOMING) {
    //   fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
    // }
    else if (getPath() === LISTINGPAGES.UPCOMING) {
      if (currentId && currentId.id) {
        fetchDiamondList(payload, [{ diamondSearchId: currentId.id }], setResponse);
      } else {
        fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
      }
    } else if (getPath() === LISTINGPAGES.EXCLUSIVE) {
      if (currentId && currentId.id) {
        fetchDiamondList(payload, [{ diamondSearchId: currentId.id }], setResponse);
      }
    } else if (getPath() === LISTINGPAGES.CART) {
      fetchCart(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.WATCHLIST) {
      fetchWatch(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.REMINDER) {
      fetchReminder(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.CONFIRM) {
      fetchConfirmStone(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.STONE_OF_DAY) {
      fetchStoneOfDay(payload, [{ sectionType: 11 }], setResponse);
    } else if (getPath() === LISTINGPAGES.NEWARR) {
      fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
    } else if (getPath() === LISTINGPAGES.MATCHPAIR) {
      fetchMatchPair(payload, window.location.search.split('?')[1], setResponse);
    } else if (getPath() === LISTINGPAGES.SPECIAL) {
      fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.EXCLUSIVE, setResponse);
    } else if (getPath() === LISTINGPAGES.SHOW) {
      if (location?.state?.fromShow) {
        if (location?.state?.id || currentId?.id) {
          fetchDiamondList(payload, [{ diamondSearchId: location?.state?.id || currentId?.id }], setResponse);
        }
      } else {
        fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.SW, setResponse);
      }
    } else if (getPath() === LISTINGPAGES.NOTE) {
      fetchNotes(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.OFFICE) {
      fetchOfficeList(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.FORMAL_MEET) {
      fetchFormalMeetList(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.QUOTEDAY) {
      const showId = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-quoteDay`)).id;
      fetchDiamondList(payload, [{ showId }], setResponse);
      // fetchQuotedayDiamonds(setResponse);
    } else if (getPath() === LISTINGPAGES.SUGGESTED_STOCK) {
      fetchDiamondList(payload, [{ sectionType: 14 }], setResponse);
    } else if (getPath() === LISTINGPAGES.ENQUIRY) {
      fetchEnquiries(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.HOLD) {
      fetchBlockDiamonds(1, setResponse);
    }
  }, [state.page, state.limit, sortRef.current, state.sum, ids]);

  const clearAllSelectedRows = useCallback(() => {
    if (isMobile()) {
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    } else {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) {
        row.classList.remove('selectTr');
      }
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, [dispatch]);

  //const clearAllSelectedRows = useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);

  const getSelection = () => {
    if (isaccount && !isMobile()) return null;
    return (
      <>
        <SelectStone
          sum={state.sum}
          currentType={currentType.current}
          profilListing={
            isaccount
              ? 'profileStonSelect'
              : getPath() === LISTINGPAGES.QUOTEDAY
                ? 'listingSelected'
                : 'diamondListStone'
          }
          promotionDis={promotionDis}
          showDis={showDis}
        />
      </>
    );
  };

  // const getFilter = () => {
  //   return (
  //     getPath() === LISTINGPAGES.OFFICE && (
  //       <div className="status-block">
  //         <span>Filter: </span>
  //         <span>
  //           <Select onChange={setStatus} defaultValue={status}>
  //             {Booth_Appointment.appointmentFor?.map(({ name, id }) => {
  //               return (
  //                 <Select.Option key={id} value={id}>
  //                   {name}
  //                 </Select.Option>
  //               );
  //             })}
  //           </Select>
  //         </span>
  //       </div>
  //     )
  //   );
  // };

  const getListTop = () => {
    return (
      <ListingTop
        listGridIcon={
          location.pathname.split('/')[1] === 'account' || getPath() === LISTINGPAGES.QUOTEDAY ? null : true
        }
        timer={getPath() === LISTINGPAGES.QUOTEDAY ? true : false}
        Cart={getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.WATCHLIST ? true : null}
        onClick={() => setListView((v) => !v)}
        image={
          listView ? require('../../assets/svg/DiamondList/grid.svg') : require('../../assets/svg/DiamondList/list.svg')
        }
      />
    );
  };

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  const onFilterChange = (_id) => {
    setIds(
      [...ids].map((i) => {
        i.isActive = i.id === _id;
        return i;
      }),
    );
    setState({ ...state, page: 1 });
  };

  const handleRemoveFilter = (_id) => {
    const __ids = filter(ids, function (val) {
      return val.id !== _id;
    });
    history.push(`/${LISTINGPAGES.LIST}?${join(map(__ids, 'id'), ',')}`);
  };

  const getBooth = () => {
    return (
      getPath() === LISTINGPAGES.SHOW && (
        <div className="boothAppointment   " onClick={() => setVisible.true()}>
          <div className="d-flex listingAction">
            <a className="  boothAppointmentButton  buttonHlightlight">
              <span>Booth Appointment</span>
              <img
                style={{ height: '1em', width: 'auto', marginBottom: '-0.2em', marginLeft: '0.25rem' }}
                src={`${BASE_URL}/${showData?.flagImage}`}
                alt="img"
              />
              {/* <USAFlag  /> */}
            </a>
          </div>
        </div>
      )
    );
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        showRefresh={getPath() === LISTINGPAGES.SHOW}
        modify={
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.MATCHPAIR ||
          getPath() === LISTINGPAGES.SHOW
        }
        onModify={() => {
          const REDIRECT = getPath() === LISTINGPAGES.MATCHPAIR ? SEARCH_PAGES.MATCH_PAIR : SEARCH_PAGES.SEARCH;
          history.push(
            `${REDIRECT}/${find(ids, { isActive: true }).id}`,
            getPath() === LISTINGPAGES.SHOW ? { fromShow: true } : undefined,
          );
        }}
        trackType={trackTypeObj[invert(LISTINGPAGES)[getPath()]]}
        {...state}
        {...props}
        currentType={currentType.current}
        nonocompare={
          !get(USER_PERMIT, 'COMPARE.view', false) ||
          getPath() === LISTINGPAGES.QUOTEDAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nowatch={
          !get(USER_PERMIT, 'WATCHLIST.view', false) ||
          getPath() === LISTINGPAGES.WATCHLIST ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.QUOTEDAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nocart={
          !get(USER_PERMIT, 'CART.view', false) ||
          getPath() === LISTINGPAGES.CART ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        noreminder={
          !get(USER_PERMIT, 'REMINDER.view', false) ||
          getPath() === LISTINGPAGES.REMINDER ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.QUOTEDAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        delete={
          (get(USER_PERMIT, 'CART.delete', false) && getPath() === LISTINGPAGES.CART) ||
          (get(USER_PERMIT, 'WATCHLIST.delete', false) && getPath() === LISTINGPAGES.WATCHLIST) ||
          (get(USER_PERMIT, 'REMINDER.delete', false) && getPath() === LISTINGPAGES.REMINDER) ||
          (get(USER_PERMIT, 'NOTES.delete', false) && getPath() === LISTINGPAGES.NOTE) ||
          (get(USER_PERMIT, 'OFFICE.delete', false) && getPath() === LISTINGPAGES.OFFICE) ||
          (get(USER_PERMIT, 'HOLD.delete', false) && getPath() === LISTINGPAGES.HOLD)
        }
        noconfirm={
          !get(USER_PERMIT, 'CONFIRM_STONE.view', false) ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.QUOTEDAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nooffice={
          !get(USER_PERMIT, 'OFFICE.view', false) ||
          getPath() === LISTINGPAGES.OFFICE ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.SHOW ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nonote={
          !get(USER_PERMIT, 'NOTES.view', false) ||
          getPath() === LISTINGPAGES.NOTE ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.QUOTEDAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
          // getPath() === LISTINGPAGES.CART
        }
        noquote={
          !get(USER_PERMIT, 'QUOTE.view', false) ||
          getPath() === LISTINGPAGES.QUOTE ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.STONE_OF_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        update={
          (get(USER_PERMIT, 'REMINDER.update', false) && getPath() === LISTINGPAGES.REMINDER) ||
          (get(USER_PERMIT, 'NOTES.update', false) && getPath() === LISTINGPAGES.NOTE)
        }
        nofinalcalc={
          !get(USER_PERMIT, 'FINAL_CALCULATIONS.view', false) ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        // noenquiry={
        //   !get(USER_PERMIT, 'ENQUIRY.view', false) ||
        //   getPath() === LISTINGPAGES.ENQUIRY ||
        //   getPath() === LISTINGPAGES.CONFIRM ||
        //   getPath() === LISTINGPAGES.UPCOMING ||
        //   getPath() === LISTINGPAGES.LIST
        // }
        nohold={
          !get(USER_PERMIT, 'HOLD.view', false) ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.HOLD
        }
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        onPaginationChange={onPaginationChange}
        nopaginate={getPath() === LISTINGPAGES.MATCHPAIR || getPath() === LISTINGPAGES.FORMAL_MEET}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        promotionDis={promotionDis}
        offerDis={offerDis}
        showDis={showDis}
        moveToBidCart={
          getPath() === LISTINGPAGES.CART && !isEmpty(bidConfig) && bidConfig.sub_types?.subTypeCode === 'BIWI'
        }
      />
    );
  };

  const classList = ['.HeaderSticky', '.selectStonHeader', '.accountTopBlock', '.accountTabBox', '.botoomStickyBar'];
  const gridHeight = getGridHeight(classList);

  const getGridView = () => {
    return (
      <>
        <DiamondGridView data={state.data} loading={state.loading} currentType={currentType.current} />
        {emptyData(state.loading, state.data.length)}
      </>
    );
  };

  const boothPopUp = React.useMemo(() => {
    return <BoothAppointment visible={visible} currentType={currentType.current} onClose={() => setVisible.false()} />;
  }, [setVisible, visible]);
  const getBoothPopUp = () => boothPopUp;

  const getTitle = () => {
    const exclusive = exclusiveTitle && exclusiveTitle.find((list) => `?${list.id}` === window.location.search)?.name;
    const count = getPath() == LISTINGPAGES.SHOW ? '' : ' (' + (state?.count ? Number(state.count) : 0) + ')';
    return (
      (getPath() === LISTINGPAGES.EXCLUSIVE
        ? exclusive
        : getPath() === LISTINGPAGES.SHOW
          ? showData?.fullName
          : TITLE[getPath()]) + count
    );
  };

  return (
    <>
      {isMobile() ? (
        <MobileViewList
          {...props}
          {...state}
          listView={listView}
          currentType={currentType.current}
          parent={{
            boothPopUp,
            getTitle,
            getListTop,
            getSelection,
            getGridView,
            getAction,
            getBooth,
            // getFilter,
          }}
        />
      ) : //`${location.pathname.split('/')[1] === 'account'}`
        isaccount ? (
          <div className="searchResultListWrapper profilePage">
            <div className="searchInnerResult">
              <div className="position-relative">{getSelection()}</div>
              {/* {getFilter()} */}
              <div
                className="searchResultTable profilePageTable"
                style={
                  `${location.pathname.split('/')[1] === 'account'}`
                    ? {
                      height: `${checked}`
                        ? `${location.pathname}` === '/account/confirm-list'
                          ? `calc(100vh - 210px)`
                          : `calc(100vh - 277px)`
                        : `calc(100vh - 210px)`,
                      overflow: 'auto',
                    }
                    : { height: gridHeight - 17, overflow: 'auto' }
                }
              >
                {getPath() === LISTINGPAGES.FORMAL_MEET ? (
                  <AntTable dataSource={state.data} pagination={false} columns={state.columns} page={state.page} />
                ) : (
                  <Table
                    data={state.data}
                    loading={state.loading}
                    ref={tableRef}
                    handleSort={handleSort}
                    currentType={currentType.current}
                    defaultChecked={state.defaultChecked}
                    page={state.page}
                    columns={state.columns}
                  />
                )}
                {emptyData(state.loading, state.data.length)}
              </div>
              {getPath() === LISTINGPAGES.FORMAL_MEET ? '' : getAction()}
            </div>
          </div>
        ) : (
          <div className="searchResultListWrapper">
            <div className="selectStonHeader">
              <div className="d-flex justify-content-between align-end width-100  pos_rel flex-wrap">
                <div className="d-flex align-items-center  flex-wrap search_para">
                  <div className="d-flex width-full item-centre justify-space-between">
                    <Heading title={getTitle()} count={state.count} />
                    {getBooth()}
                    <div className="diamondlistInfo">
                      <Popover
                        overlayClassName="infoPopover"
                        placement="bottomLeft"
                        content={
                          <p>
                            <b>Note:</b>
                            1) The prices mentioned over here are fixed and hence not negotiable.
                            <p>
                              2) The grading, patterns & parameters mentioned on our website beyond GIA's grading is
                              solely our perspective based on examinations conducted by our grading department and we do
                              not hold ourself responsible for any conflicts in this regard.
                            </p>
                          </p>
                        }
                        title={false}
                      >
                        <img src={require('../../assets/svg/info.svg')} alt="" />
                      </Popover>
                    </div>
                    <AllStatusShow show={getPath() === LISTINGPAGES.SHOW} />
                  </div>
                  {upcomingData.length > 0 && (
                    <p
                      style={{
                        paddingLeft: '15px',
                        fontSize: '17px',
                        color: '#c950c1',
                        fontWeight: 600,
                        paddingBottom: '10px',
                      }}
                    >
                      Please connect with your sales representative for more information
                    </p>
                  )}
                  {SEARCH_TAGS && (
                    <div className="d-flex width-100 listTag flex-wrap">
                      {state.seachFilterTag && <MasterTags filterData={state.seachFilterTag.displayData} />}
                    </div>
                  )}
                </div>

                <div className={`d-flex align-end ${MULTIPLE_SEARCH && ids.length > 1 && 'multi-search'}`}>
                  <div>
                    {MULTIPLE_SEARCH && ids.length > 1 && (
                      <FilterTop handleRemoveFilter={handleRemoveFilter} handleFilterChange={onFilterChange} ids={ids} />
                    )}
                  </div>
                  {getListTop()}
                </div>
                {getSelection()}
              </div>
            </div>
            <div className="searchInnerResult">
              {listView ? (
                <GridHeightContainer className="searchResultTable" subtractFrom={classList.join(',')}>
                  <Table
                    ref={tableRef}
                    data={state.data}
                    loading={state.loading}
                    handleSort={handleSort}
                    currentType={currentType.current}
                    defaultChecked={state.defaultChecked}
                    // page={state.page}

                    columns={state.columns}
                    currentPage={state.page}
                  />

                  {emptyData(state.loading, state.data.length)}
                </GridHeightContainer>
              ) : (
                <GridHeightContainer className="searchResultGridView" subtractFrom={classList.join(',')}>
                  {getGridView()}
                </GridHeightContainer>
              )}
              {getAction()}
            </div>
            {getBoothPopUp()}
          </div>
        )}
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(React.memo(withRouter(DiamondList)));
