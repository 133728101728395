import React, { useReducer, useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cloneDeep, findIndex, get, isNumber, isEmpty } from 'lodash';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import Table from '../DiamondList/TableBack';
import { getState, isArrayEqual } from '../DiamondList';
import {
  fetchBid,
  getColumn,
  TITLE,
  LISTINGPAGES,
  fetchBidCart,
  isMobile,
  handleCartBidUpsert,
} from '../DiamondList/DiamondListFunctions';
import { getBidType } from '../NewArrivalBidIt';
import Heading from '../common/Heading';
import SelectStone from '../common/DiamondListing/SelectStone';
import { Radio } from 'antd';
import Countdown from '../Offer/Timer';
import IntlMessages from '../../util/IntlMessages';
import { DIAMOND_BID, LOCAL_STORAGE_VAR } from '../../constants/Common';
import BidListMobile from './BidListMobile';
import { getCurrentTime, getNextBidData } from '../../services/util';
import UtilService from '../../services/util';
import { isNumeric } from '../../util/utils';
import NoDataShow from '../common/NoDataShow';
import BidCartList from './BidCartList';
import OpenNotification from 'components/common/CommonButton/OpenNotification';
const BIDTAB = {
  LIVE: '1',
  MYBIDCART: '2',
  HISTORY: '3',
};

const BidList = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [tab, setTab] = useState(window.location.search === '?cart' ? BIDTAB.MYBIDCART : BIDTAB.LIVE);
  const [showError, setShowError] = useState(false);
  const [dataList, setDataList] = useState();
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const [date, setDate] = useState({
    from: moment().startOf('day').add(-6, 'days'),
    to: moment(),
  });
  const [currTime, setCurrTime] = useState('');
  const [nextBidData, setNextBidData] = useState({});

  const selectedRows = useSelector((state) => state.diamondData.selectedRows[currentType.current]);
  const check = selectedRows;

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip, tab]);

  const clearAllSelectedRows = useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);

  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === 'E_NOT_FOUND') {
            setState({ ...state, loading: false, data: [] });
          }
        });
      setCurrTime(time.iso);
      setNextBidData(nextBid ? nextBid : []);
      nextBid && nextBid.sub_types && fetch();
      const row = await fetchBidCart().then();
      const data = row.list?.map((x) => ({
        ...x.diamond,
      }));
      setDataList(data);
    }
    setTimers();
    check && tab === BIDTAB.LIVE && clearAllSelectedRows();
  }, [state.page, state.limit, date, tab]);

  const setResponse = (res) => {
    setShowError(res?.count === 0);
    const columns = fetchcolumn();
    if (res) {
      let diamondData = res.data,
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData &&
            diamondData.map((d) => {
              if (defaultChecked.includes(d.id)) {
                d['isDefaultChecked'] = true;
              }
              return d;
            });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...state,
        ...res,
        columns,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, columns, defaultChecked: [] });
    }
  };

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current],
  );

  const bidEndTime = async () => {
    const nextBid = await getNextBidData()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') {
          setState({ ...state, loading: false, data: [] });
        }
      });
    setNextBidData(nextBid ? nextBid : []);
    getSelection();
  };
  const checkInterval = (value, oldValue, discount, nextBidData) => {
    value = Number(value);
    oldValue = Number(oldValue);

    const diffInterval = nextBidData.sub_types?.diffInterval;
    const v1 = parseInt(Math.round(value * 100));
    const v2 = parseInt(Math.round(oldValue * 100));
    const diff = Math.abs(v1 - v2);
    const inInterval = diff % (diffInterval * 100) === 0 || diff === 0;
    if (!inInterval) {
      OpenNotification({
        type: 'error',
        title: `You can only change discount in ${Math.abs(diffInterval)}% interval`,
      });
    }
    return inInterval;
  };
  const handleDiscountChange = (value, row, datas, nextBidData) => {
    let lDataList = localStorage.getItem('data');
    lDataList = lDataList ? JSON.parse(lDataList) : [...datas];
    const data = lDataList;
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    value = Number(value); // converting to number
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types?.calColumn;
    data[index].back = back;
    const subTypeCode = nextBidData.sub_types?.subTypeCode;
    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = checkInterval(value, oldValue, back, nextBidData);
    const maxPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
    const upInterval = nextBidData.sub_types?.upwardInterval;
    const downInterval = nextBidData.sub_types?.downwardInterval;
    const max =
      calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (upInterval / 100) : upInterval + Math.abs(back);
    const min = calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (downInterval / 100) : back - downInterval;
    const minDiscount = data[index].ctPr + data[index].ctPr * (downInterval / 100);
    const disc = (parseFloat(minDiscount) * 100) / data[index].rap - 100;
    if (calColumn !== 'ctPr') {
      if (value >= min && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
        data[index].bidDiscount = value;
        const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
        data[index].bidPricePerCarat = bigPricePerCarat;
        data[index].bidAmount = bigPricePerCarat * data[index].crt;
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        if (isInIntervalDiff) {
          if (!(value >= back)) {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
          } else {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
          }
        }
      }
    } else {
      if (
        parseFloat(maxPricePerCarat) >= min &&
        parseFloat(maxPricePerCarat) <= max &&
        isInIntervalDiff &&
        value >= -100 &&
        value <= 100
      ) {
        data[index].bidDiscount = value;
        const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
        data[index].bidPricePerCarat = bigPricePerCarat;
        data[index].bidAmount = bigPricePerCarat * data[index].crt;
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        if (isInIntervalDiff) {
          if (!(parseFloat(maxPricePerCarat) >= data[index].ctPr)) {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
          } else {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
          }
        }
      }
    }
    setDataList(data);
  };

  const handleDiscountBlur = (...args) => handleDiscountChange(...args, true);

  const getNewBid = (value, row, datas, nextBidData) => {
    const data = [...datas];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - parseFloat(value) / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types?.calColumn;
    data[index].back = back;

    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = data[index].isFcCol
      ? checkInterval(value, data[index].bidPricePerCarat, back, nextBidData)
      : checkInterval(discountValue, oldValue, back, nextBidData);
    const upInterval = nextBidData.sub_types?.upwardInterval;
    const downInterval = nextBidData.sub_types?.downwardInterval;
    const subTypeCode = nextBidData.sub_types?.subTypeCode;
    const max =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
        : upInterval + Math.abs(back);
    const min = calColumn === 'ctPr' || data[index].isFcCol ? data[index].ctPr : back + downInterval;
    const minValue = data[index].ctPr;
    const minDisc = parseFloat(data[index].rap + ((back + downInterval) * data[index].rap) / 100).toFixed(2);
    if (calColumn !== 'ctPr' && !data[index].isFcCol) {
      if (
        parseFloat(discountValue) >= min &&
        parseFloat(discountValue) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        data[index].bidPricePerCarat = value;
        data[index].bidAmount = (value || 0) * data[index].crt;
        data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = data[index].isFcCol
          ? data[index].bidPricePerCarat
          : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        isInIntervalDiff &&
          OpenNotification({
            type: 'error',
            title:
              subTypeCode !== 'BIWI'
                ? 'Your bidding value must be higher than base value.'
                : `Your bidding amount can not be lower then ${parseFloat(minValue * data[index].crt).toFixed(
                  2,
                )}. Please, try again.`,
          });
      }
    } else {
      if (
        parseFloat(value) >= min &&
        parseFloat(value) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        data[index].bidPricePerCarat = value;
        data[index].bidAmount = (value || 0) * data[index].crt;
        data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = data[index].isFcCol
          ? data[index].bidPricePerCarat
          : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        isInIntervalDiff &&
          OpenNotification({
            type: 'error',
            title:
              subTypeCode !== 'BIWI'
                ? 'Your bidding value must be higher than base value.'
                : `Your bidding amount can not be lower then ${parseFloat(minValue * data[index].crt).toFixed(
                  2,
                )}. Please, try again.`,
          });
      }
    }
    setDataList(data);
  };

  const handleNewBidBlur = (...args) => getNewBid(...args, true);

  const handleValueChange = (value, row, datas, nextBidData) => {
    const data = [...datas];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };

    const bidPricePerCarat = parseFloat(value / data[index].crt).toFixed(2);
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - bidPricePerCarat / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types?.calColumn;
    data[index].back = back;
    const subTypeCode = nextBidData.sub_types?.subTypeCode;
    const upInterval = nextBidData.sub_types?.upwardInterval;
    const downInterval = nextBidData.sub_types?.downwardInterval;
    const minCtPr = data[index].ctPr;
    const minAmount = parseFloat(minCtPr).toFixed(2) * data[index].crt;

    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = data[index].isFcCol
      ? checkInterval(value, data[index].bidAmount, back, nextBidData)
      : checkInterval(discountValue, oldValue, back, nextBidData);
    const max =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
        : upInterval + Math.abs(back);
    const min = calColumn === 'ctPr' || data[index].isFcCol ? data[index].ctPr : back + downInterval;
    if (calColumn !== 'ctPr' && !data[index].isFcCol) {
      if (
        parseFloat(discountValue) >= min &&
        parseFloat(discountValue) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        if (data[index].isFcCol) {
          data[index].bidAmount = value;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
        } else {
          data[index].bidDiscount = discountValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + (discountValue * data[index].rap) / 100).toFixed(
            2,
          );
          data[index].bidAmount = value;
        }
      } else {
        if (data[index].isFcCol) {
          data[index].bidAmount = data[index].bidAmount;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        } else {
          data[index].bidDiscount = data[index].back;
          data[index].bidPricePerCarat = parseFloat(
            data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
          ).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        }
      }
    } else {
      if (
        parseFloat(bidPricePerCarat) >= min &&
        parseFloat(bidPricePerCarat) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        if (data[index].isFcCol) {
          data[index].bidAmount = value;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
        } else {
          data[index].bidDiscount = discountValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + (discountValue * data[index].rap) / 100).toFixed(
            2,
          );
          data[index].bidAmount = value;
        }
      } else {
        if (data[index].isFcCol) {
          data[index].bidAmount = data[index].bidAmount;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        } else {
          data[index].bidDiscount = data[index].back;
          data[index].bidPricePerCarat = parseFloat(
            data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
          ).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        }
      }
    }
    setDataList(data);
  };

  const handleValueBlur = (...args) => handleValueChange(...args, true);
  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current, date };
    if (tab === BIDTAB.LIVE) {
      fetchBid(
        payload,
        nextBidData.id ? nextBidData.id : '',
        nextBidData.sub_types ? nextBidData.sub_types.subTypeCode : '',
        //tab === BIDTAB.LIVE ? [getBidType()] : [DIAMOND_BID.TYPE.OPEN, DIAMOND_BID.TYPE.BLIND],
        tab === BIDTAB.LIVE ? [DIAMOND_BID.STATUS.PENDING] : [DIAMOND_BID.STATUS.WIN, DIAMOND_BID.STATUS.LOSS],
        setResponse,
        tab === BIDTAB.HISTORY,
      );
    }
  }, [state.page, state.limit, sortRef.current, state.sum, date, tab, nextBidData]);

  const fetchcolumn = async () => {
    const nextBidData = await getNextBidData().then();
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    const ctr = Columns.filter((el) => el.id === 'ctPr');
    const amnt = Columns.filter((el) => el.id === 'amt');
    Columns = Columns.filter((el) => el.id !== 'ctPr' && el.id !== 'amt');

    const downInterval = nextBidData.sub_types.downwardInterval * -1;
    const calColumn = nextBidData.sub_types.calColumn;
    const row = await fetchBidCart().then();
    const data = row.list
      ?.map((x) => {
        return {
          ...x.diamond,
        };
      })
      .map((x) => {
        const minDis =
          x.ctPr < x.rap
            ? -1 * Math.abs(parseFloat(Number(x.back)).toFixed(2)) + downInterval
            : Math.abs(parseFloat(Number(x.back)).toFixed(2)) + downInterval;
        if (calColumn !== 'ctPr') {
          return {
            ...x,
            bidDiscount: minDis,
            bidPricePerCarat: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2),
            bidAmount: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2) * x.crt,
          };
        } else {
          const minCtPr = parseFloat(x.ctPr + x.ctPr * (downInterval / 100)).toFixed(2);
          return {
            ...x,
            bidPricePerCarat: parseFloat(x.ctPr + x.ctPr * (downInterval / 100)).toFixed(2),
            bidAmount: parseFloat(minCtPr).toFixed(2) * x.crt,
            bidDiscount: parseFloat((1 - minCtPr / x.rap) * -100).toFixed(2),
          };
        }
      });
    const columns = [
      ...Columns.slice(0, index + 1),
      ctr && ctr[0] ? ctr[0] : null,
      amnt && amnt[0] ? amnt[0] : null,
      {
        Header: 'Bid Disc%',
        accessor: 'bidDiscount',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.back).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidDiscount)
              ? parseFloat(row.original.bidDiscount).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidDiscount) {
              setValue(parseFloat(row.original.bidDiscount).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = (e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleDiscountBlur(_value, row.original, data, nextBidData)
              : handleDiscountBlur(0, row.original, data, nextBidData);
          };

          const sign = Number(value) > 0 ? '+' : '';
          if (row.original.isFcCol) {
            return '-';
          }
          return (
            <div className="tableInput">
              <span className="add-sign">{sign}</span>
              <input
                type="number"
                style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          );
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.ctPr).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidPricePerCarat)
              ? parseFloat(row.original.bidPricePerCarat).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidPricePerCarat) {
              setValue(parseFloat(row.original.bidPricePerCarat).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);
          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleNewBidBlur(_value, row.original, data, nextBidData)
              : handleNewBidBlur(0, row.original, data, nextBidData);
          });

          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmount',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.amt).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidAmount)
              ? parseFloat(row.original.bidAmount).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidAmount) {
              setValue(parseFloat(row.original.bidAmount).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback(
            (e) => {
              e.preventDefault();
              // this.setState({ showModal: true });
              const _value = e.target.value;
              if (isNumeric(_value)) setValue(_value);
            },
            [value],
          );

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            // this.setState({ showModal: false });
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleValueBlur(_value, row.original, data, nextBidData)
              : handleValueBlur(0, row.original, data, nextBidData);
          }, []);
          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },
      ...Columns.slice(index + 1, Columns.length),
    ]
      .filter((el) => el && el)
      .map((col) => ({ ...col, id: col.accessor }));
    setDataList(data);
    return columns;
  };

  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const TIME = [
    { name: 'Today', dateFrom: moment().startOf('day'), dateTo: moment() },
    {
      name: 'Yesterday',
      dateFrom: moment().startOf('day').add(-1, 'days'),
      dateTo: moment().endOf('day').add(-1, 'days'),
    },
    {
      name: 'Last 7 Days',
      dateFrom: moment().startOf('day').add(-6, 'days'),
      dateTo: moment(),
    },
    {
      name: 'This Month',
      dateFrom: moment().startOf('month'),
      dateTo: moment(),
    },
    { name: 'This Year', dateFrom: moment().startOf('year'), dateTo: moment() },
    { name: 'All', dateFrom: moment(user.createdAt), dateTo: moment() },
    { name: 'Custom Range', datePicker: true },
  ];

  const getTitle = () => {
    if (tab === BIDTAB.MYBIDCART) {
      return JSON.stringify(nextBidData) !== '[]' && dataList && dataList.length
        ? TITLE.BID + ' (' + dataList.length + ')'
        : TITLE.BID + ' (0)';
    } else {
      return JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length
        ? TITLE.BID + ' (' + state.count + ')'
        : TITLE.BID + ' (0)';
    }
  };

  const getSelection = () => {
    return tab === BIDTAB.HISTORY ? null : (
      <SelectStone
        profilListing={getBidType() ? 'listingSelected diamondListStone my_bid' : ''}
        sum={state.sum}
        currentType={currentType.current}
      />
    );
  };

  const cartBidStoneMobileCreate = () => {
    if (!isEmpty(check)) {
      const newData = [];
      dataList.map((d) => {
        check.map((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });

      let showModal = false;
      check.map((x) => {
        if (Number(x.bidDiscount ? x.bidDiscount : x.back) === Number(x.back)) {
          showModal = true;
        }
      });
      if (showModal && nextBidData.sub_types.subTypeCode !== 'BIWI') {
        OpenNotification({
          type: 'error',
          title: 'Bid discount and discount can not be same for bid.',
        });
      } else {
        handleCartBidUpsert(
          check,
          nextBidData.id,
          nextBidData.sub_types.subTypeCode,
          nextBidData.sub_types.result,
          // (flag) => {
          //   self.props.onClose();
          //   self.props.clearAll();
          //   if (flag && self.props.fetch) self.props.fetch();
          // },
        );
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s).',
      });
    }
  };

  const getAction = () => {
    return tab === BIDTAB.LIVE ? (
      <DiamondListingAction
        {...state}
        {...props}
        nocart
        nowatch
        nonote
        noreminder
        noconfirm
        noquote
        update={get(USER_PERMIT, 'MY_BID.update', false)}
        nonocompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        nohold
        nofinalcalc
        // noenquiry={!get(USER_PERMIT, 'ENQUIRY.view', false)}
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        currentType={currentType.current}
        nopaginate
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    ) : (
      <DiamondListingAction
        cartbidStoneMobile
        {...state}
        {...props}
        nocart
        cartBidStoneMobileCreate={cartBidStoneMobileCreate}
        nowatch
        nonote
        noreminder
        noconfirm
        noquote
        nodownload
        noprint
        currentType={currentType}
        nopaginate
        nonocompare
        nooffice
        nohold
        nofinalcalc
        // noenquiry
        noshare
        clearAll={clearAllSelectedRows}
        fetch={fetchcolumn}
      />
    );
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className="bidEndBlock d-flex align-items-center">
        {JSON.stringify(nextBidData) !== '[]' ? (
          <span className="bidEndLabel">
            <IntlMessages id="app.BidEndIn" /> :
          </span>
        ) : (
          ''
        )}
        {JSON.stringify(nextBidData) !== '[]' ? (
          <Countdown
            currTime={currTime}
            date={
              nextBidData &&
              nextBidData.sub_types &&
              nextBidData.sub_types.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate)
            }
            bidEndTime={bidEndTime}
          />
        ) : (
          ''
        )}
      </div>
    ) : null;
  };

  const getRadio = () => {
    return (
      <Radio.Group
        value={tab}
        onChange={(e) => {
          setTab(e.target.value);
          if (!window.location.search && e.target.value === '2') {
            window.history.pushState(`${window.location.pathname}`, '', `?cart`);
          } else {
            window.history.pushState(`${window.location.pathname}`, '', '/my-bid');
          }
        }}
      >
        <Radio value={BIDTAB.LIVE}>
          <IntlMessages id="app.LiveBid" />
        </Radio>
        <Radio value={BIDTAB.MYBIDCART}>
          <IntlMessages id="app.MyBidCart" />
        </Radio>
      </Radio.Group>
    );
  };

  const goBack = () => {
    return (
      <Link
        className="commonOutline smallButton"
        to={nextBidData?.sub_types?.subTypeCode === 'BIWI' ? '/' + LISTINGPAGES.SEARCH_DIAMOND : '/' + LISTINGPAGES.BID}
      >
        <IntlMessages id="app.GoToBid" />
      </Link>
    );
  };
  if (isMobile()) {
    if (tab === BIDTAB.LIVE) {
      return (
        <BidListMobile
          {...props}
          {...state}
          checked={check}
          data={state.data}
          displayStatus={tab === BIDTAB.HISTORY}
          currentType={currentType.current}
          parent={{
            getTitle,
            getSelection,
            getBidBlock: getBidBlock(),
            goBack: goBack(),
            getRadio: getRadio(),
            getAction,
          }}
          myBidList={tab === BIDTAB.LIVE}
        />
      );
    } else {
      return (
        <BidListMobile
          {...props}
          {...state}
          checked={check}
          data={dataList}
          bidCartPopup={{
            data: dataList,
            handleNewBidBlur,
            handleDiscountBlur,
            handleValueBlur,
            nextBidData,
          }}
          displayStatus={tab === BIDTAB.HISTORY}
          currentType={currentType.current}
          parent={{
            getTitle,
            getSelection,
            getBidBlock: getBidBlock(),
            getRadio: getRadio(),
            goBack: goBack(),
            getAction,
          }}
        // bidCartPopup={tab === BIDTAB.MYBIDCART}
        />
      );
    }
  }
  localStorage.setItem('data', JSON.stringify(dataList));
  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
          <Heading title={getTitle()} />
          {JSON.stringify(nextBidData) !== '[]' ? (
            <div className="d-flex">
              <div className="d-flex align-items-center j-content-center mr-10 flex-wrap">
                <div style={{ display: 'block' }}>{getRadio()}</div>
                {/* {getDropDown()} */}
                {goBack()}
              </div>
              {getBidBlock()}
            </div>
          ) : (
            ' '
          )}
          {JSON.stringify(nextBidData) !== '[]' ? getSelection() : ''}
        </div>
      </div>
      {tab === BIDTAB.MYBIDCART ? (
        <BidCartList />
      ) : (
        <div className="searchInnerResult">
          <div
            className="searchResultTable"
            style={{ height: check?.length ? `calc(100vh - 219px)` : `calc(100vh - 170px)`, overflow: 'auto' }}
          >
            {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? (
              <Table
                noCheckBox={tab === BIDTAB.HISTORY}
                data={state.data}
                columns={state.columns}
                loading={state.loading}
                ref={tableRef}
                handleSort={handleSort}
                currentType={currentType.current}
                defaultChecked={state.defaultChecked}
              />
            ) : (
              ''
            )}
            {showError ? (
              <NoDataShow
                message={'No Bid submission Found, Hurry UP..!!! Submit your bid before bid ends.'}
                noData
                subTypeCode={nextBidData?.sub_types?.subTypeCode === 'BIWI'}
              />
            ) : (
              ''
            )}
            {JSON.stringify(nextBidData) === '[]' ? (
              <NoDataShow message={'No Bid is active now. Please purchase diamond!!'} redirectURL />
            ) : (
              ''
            )}
            {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? getAction() : ''}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(BidList);
