import React, { Component } from 'react';
import { connect } from 'react-redux';
import UtilService from '../services/util';
import find from 'lodash/find';
import NoDataShow from './common/NoDataShow';
import Heading from './common/Heading';
import MagnifierImage from './DiamondDetail/MagnifierImage';
import { handleAlter, LISTINGPAGES } from './DiamondList/DiamondListFunctions';
import { LOCAL_STORAGE_VAR, FILE_URLS, DIAMOND_BID } from '../constants/Common';
import { DIAMOND_DATA_BY_ID } from '../constants/apiConstant';
import { floatkeys, roundkeys, upcomingkeys } from './DiamondList/TableBack';
import DiamondListingAction from './common/DiamondListing/DiamondListingAction';
import { getGridHeight } from './DiamondList';
import { formatNumber } from './common/DiamondListing/SelectStone';
import CheckBox from './common/CheckBox';
import { getBidType } from './NewArrivalBidIt';
import IntlMessages from '../util/IntlMessages';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

const currentType = 'DiamondCompare';

class DiamondCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      checked: [],
      inTrackDiamonds: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }

  fetch = () => {
    const self = this;
    const ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
      : false;
    if (ids.length === 0 || ids.length === 1) {
      this.setState({ data: [] });
      return;
    }
    const obj = DIAMOND_DATA_BY_ID;

    const order = confirmCompare ? { orderDiamond: true } : {};
    obj.request = {
      id: ids,
      ...order,
      isReturnTotal: true,
      isReturnIntrack: true,
    };
    this.setState({ loading: true });
    UtilService.callApi(obj, function (err, data) {
      let rows = [];
      let inTrackDiamonds = [];
      if (data && data.code === 'OK') {
        rows = data.data.list;
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
      self.setState({ data: rows, inTrackDiamonds, loading: false });
    });
  };

  getMap = (name, key, extra = '', isLink) => {
    return (
      <tr>
        <td>{name}</td>
        {this.state.data.map((x, i) => {
          return (
            <td
              key={i}
              style={{
                textDecoration: isLink && 'underline',
              }}
              onClick={() => {
                if (isLink) {
                  window.open(`/${LISTINGPAGES.DETAIL}/${x.id}`, '_blank');
                }
              }}
            >
              {(!x[key]
                ? '-'
                : x.wSts == 'U' && upcomingkeys.includes(key)
                  ? '-'
                  : floatkeys.includes(key)
                    ? formatNumber(parseFloat(x[key]).toFixed(2))
                    : roundkeys.includes(key)
                      ? formatNumber(Math.round(x[key]))
                      : x[key]) + extra}
            </td>
          );
        })}
      </tr>
    );
  };

  removeCompare = (id) => {
    let ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    ids = ids.filter((el) => el !== id);
    if (ids.length === 1) ids = [];
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(ids));
    this.setState({
      data: this.state.data.length === 2 ? [] : this.state.data.filter((el) => el.id !== id),
    });
  };

  render() {
    const gridHeight = getGridHeight(['HeaderSticky', 'diamondListingAction', 'comparPageHead'], 25);
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    // Don't Change fsNow
    const fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`))
        .split('/')
        .filter((x) => !['', 'account'].includes(x))[0]
      : '';

    const path = fsNow.split('?')?.[0];
    return (
      <>
        <div className="compareMainPage">
          <div className="d-flex justify-content-between align-items-center mb-10 comparPageHead">
            <Heading title={<IntlMessages id="app.CompareStone" />} count={this.state.data.length} className="mb-0" />
            {this.props.location?.state?.currentType === 'MyDealOfTheDay' ? (
              <div className="d-flex">
                <div className="d-flex align-items-center j-content-center mr-10 flex-wrap">
                  <Link className="commonOutline smallButton" to={LISTINGPAGES.MYDEALOFDAYLIST}>
                    <span>{`< My Deal Of The Day`}</span>
                  </Link>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {!this.state.loading && !this.state.data.length ? <NoDataShow /> : null}
          {!this.state.loading && this.state.data.length ? (
            <div className="compareTable" style={{ height: gridHeight, overflow: 'auto' }}>
              <table>
                <tr>
                  <td className="compareHead">Diamond Image</td>
                  {this.state.data.map((x, i) => {
                    return (
                      <td key={i} className="compareHead compareImage">
                        {x.img && (
                          <MagnifierImage
                            image={FILE_URLS.img.replace('***', x.vStnId)}
                            className="diamondCompareImg"
                          />
                        )}
                        <div className="closeImage" onClick={() => this.removeCompare(x.id)}>
                          <img src={require('../assets/svg/Compare/close-red.svg')} alt="" />
                        </div>
                        <div className="checkImageBox">
                          <CheckBox
                            checked={find(this.state.checked, {
                              stoneId: x.stoneId,
                            })}
                            onChange={() => {
                              const checked = handleAlter(this.state.checked, x, 'stoneId');
                              this.setState({ checked });
                            }}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {this.getMap('Stone No', 'vStnId', '', true)}
                {this.getMap('Lab', 'lbNm')}
                {this.getMap('Shape', 'shpNm')}
                {this.getMap('Carat', 'crt')}
                {this.getMap('Color', 'colNm')}
                {this.getMap('Clarity', 'clrNm')}
                {this.getMap('Shade', 'shdNm')}
                {this.getMap('Rap ($)', 'rap')}
                {this.getMap('Discount%', 'back')}
                {this.getMap('Price/Carat ($)', 'ctPr')}
                {this.getMap('Amount ($)', 'amt')}
                {this.getMap('Certificate No', 'rptNo')}
                {this.getMap('Fluorescence', 'fluNm')}
                {this.getMap('Cut', 'cutNm')}
                {this.getMap('Polish', 'polNm')}
                {this.getMap('Symmetry', 'symNm')}
                {this.getMap('Eye Clean', 'eClnNm')}
                {this.getMap('Depth %', 'depPer')}
                {this.getMap('Table %', 'tblPer')}
                {this.getMap('Length', 'length')}
                {this.getMap('Width', 'width')}
                {this.getMap('Depth', 'height')}
                {this.getMap('Crown Angle', 'cAng')}
                {this.getMap('Crown Height', 'cHgt')}
                {this.getMap('Pav Angle', 'pAng')}
                {this.getMap('Pav Height', 'pHgt')}
                {this.getMap('Girdle', 'girdleStr')}
                {this.getMap('Culet', 'cultNm')}
                {this.getMap('H&A', 'hANm')}
              </table>
            </div>
          ) : null}
        </div>
        <DiamondListingAction
          {...this.state}
          {...this.props}
          checkedData={this.state.checked}
          bidStone={path === LISTINGPAGES.BID}
          nocompare={get(USER_PERMIT, 'COMPARE.view', false)}
          nopaginate
          currentType={currentType}
          clearAll={() => this.setState({ checked: [] })}
          fetch={this.fetch}
          noconfirm={
            !get(USER_PERMIT, 'CONFIRM_STONE.view', false) ||
            fsNow === LISTINGPAGES.CONFIRM ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          fsNow={fsNow}
          nocart={
            !get(USER_PERMIT, 'CART.view', false) ||
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.CART ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          noquote={
            !get(USER_PERMIT, 'QUOTE.view', false) ||
            fsNow === LISTINGPAGES.QUOTE ||
            fsNow === LISTINGPAGES.CONFIRM ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          nooffice={
            !get(USER_PERMIT, 'OFFICE.view', false) ||
            //getBidType() === DIAMOND_BID.TYPE.BLIND ||
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.OFFICE ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          nowatch={
            !get(USER_PERMIT, 'WATCHLIST.view', false) ||
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.WATCHLIST ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          noreminder={
            !get(USER_PERMIT, 'REMINDER.view', false) ||
            fsNow === LISTINGPAGES.CONFIRM ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING ||
            fsNow === LISTINGPAGES.DEALTHEOFDAY ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          nonote={
            !get(USER_PERMIT, 'NOTES.view', false) ||
            fsNow === LISTINGPAGES.CONFIRM ||
            path === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          nofinalcalc={
            !get(USER_PERMIT, 'FINAL_CALCULATIONS.view', false) ||
            fsNow === LISTINGPAGES.UPCOMING ||
            path === LISTINGPAGES.BID ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          // noenquiry={
          //   !get(USER_PERMIT, 'ENQUIRY.view', false) ||
          //   fsNow === LISTINGPAGES.CONFIRM ||
          //   fsNow === LISTINGPAGES.UPCOMING ||
          //   fsNow !== LISTINGPAGES.DEALTHEOFDAY
          // }
          nohold={
            !get(USER_PERMIT, 'HOLD.view', false) ||
            fsNow === LISTINGPAGES.HOLD ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY ||
            path === LISTINGPAGES.BID
          }
          noshare={
            !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
            !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
            !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false) ||
            fsNow !== LISTINGPAGES.DEALTHEOFDAY
          }
          nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false) || fsNow !== LISTINGPAGES.DEALTHEOFDAY}
          noprint={!get(USER_PERMIT, 'PRINT.view', false) || fsNow !== LISTINGPAGES.DEALTHEOFDAY}
        />
      </>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(DiamondCompare);
